import { Config } from '../config'

export class StorageClient {

    constructor(authHelper, url = "storage", chunkSize = 6000000) {
        this.authHelper = authHelper;
        this.url = `${Config.host}/${url}`
        this.chunkSize = chunkSize

        this.OnProgress = undefined
        this.OnLoad = undefined
        this.OnComplete = undefined

        this.project = {
            name: "",
            preset: "",
            fileNames: [],
            currentAudioTrack: 0,
            settings: null
        }
    }

    async upload(files) {
        let chunkData = new FormData()

        const str = JSON.stringify(this.project);
        const projectBytes = new TextEncoder().encode(str);
        const projectBlob = new Blob([projectBytes], {
            type: "application/json;charset=utf-8"
        });

        chunkData.append(`project`, projectBlob, "project.json");

        files.forEach((file, key) => {
            let chunkNumber = Math.ceil(file.size / this.chunkSize)

            for (let i = 0; i < chunkNumber; i++) {

                let start = i * this.chunkSize
                let end = Math.min((i + 1) * this.chunkSize, file.size)
                let c = file.slice(start, end)

                chunkData.append(`file`, c, `${key}.raw`);

            }

        });

        await this.uploadChunk2(chunkData)
    }

    async uploadChunk(chunkData) {
        await fetch(`${this.url}/upload`, {
            method: 'POST',
            body: chunkData,
        })
            .then((res) => res.json)
            .then((data) => console.log(data))
            .catch((err) => console.log(err))
    }

    async uploadChunk2(chunkData) {
        let request = new XMLHttpRequest();
        request.open('POST', `${this.url}/upload`);
        const token = this.authHelper.getToken();
        request.setRequestHeader("Authorization", `Bearer ${token}`)
        
        const a = (e) => {
            let percent_completed = (e.loaded / e.total) * 100
            
            if (this.OnProgress) {
                this.OnProgress(percent_completed)
            }
        }

        const b = (e) => {
            const uploadedProject = JSON.parse(request.response);
            if (this.OnLoad) {
                this.OnLoad(false)
            }

            if (this.OnComplete) {
                this.OnComplete(uploadedProject)
            }
        }

        request.upload.addEventListener('progress', a)
        
        request.addEventListener('load', b)

        if (this.OnLoad) {
            this.OnLoad(true)
        }

        request.send(chunkData);
    }
} 