import { AuthHelper } from "./authHelper";
import { UserChangeSecret, User } from "../contract/userProfile";
import { OperationResult } from "../contract/common";
import { Config } from '../config'
import authHelper from "./authHelper";

class UserProfileClient {
    /**
     * @type {AuthHelper}
     */
    authHelper;

    /**
     * 
     * @param {AuthHelper} authHelper 
     */
    constructor(authHelper) {
        this.authHelper = authHelper;
    }

    /**
     * 
     * @returns {User}
     */
    async get() {
        const token = this.authHelper.getToken();
        const res = await fetch(`${Config.authHost}/user`, {
            method: "GET", headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        const json = await res.json()
        return json;
    }

    /**
     * 
     * @param {UserChangeSecret} userChangeSecret 
     * @returns {OperationResult}
     */
    async changeSecretAsync(userChangeSecret) {
        const token = this.authHelper.getToken();
        const res = await fetch(`${Config.authHost}/user/password/change`, {
            method: "POST",
            body: JSON.stringify(userChangeSecret),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        const json = await res.json();
        return json;
    }

    /**
     * 
     * @param {User} user 
     * @returns {OperationResult}
     */
    async update(user) {
        const token = this.authHelper.getToken();
        const res = await fetch(`${Config.authHost}/user`, {
            method: "PUT",
            body: JSON.stringify(user),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        const json = await res.json();
        return json;
    }

    async resetPass() {
        const token = this.authHelper.getToken();
        const res = await fetch(`${Config.authHost}/user/password/reset`, {
            method: "GET", headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        const json = await res.json()
        return json
    }
}


export default new UserProfileClient(authHelper);