import React from "react";
import { useTranslation } from "react-i18next";


const languages = [
    {code: "ru", lang: "Russian"},
    {code: "en", lang: "English"}
];

const LanguageSelector = () => {
    const { i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng)
    }
    return (
        <div className="d-flex flex-column gap-1">
            {
                languages.map((lng) => {
                    return (
                    <button className="btn_lng_select" key={lng.code} onClick={() => changeLanguage(lng.code)}>
                        {lng.lang}
                        </button>
                    )
                })
            }
        </div>
    )
}

export default LanguageSelector;