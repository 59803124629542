import { Config } from '../config';
import authHelper from "./authHelper";

class RenderClient {
    /**
 * @type {AuthHelper}
 */
    authHelper;

    /**
     *
     * @param {AuthHelper} authHelper
     */
    constructor(authHelper) {
        this.authHelper = authHelper;
    }

    async getStoryboard(project) {
        const token = this.authHelper.getToken();
        const res = await fetch(`${Config.host}/render/${project}/storyboard`, {
            method: "GET", headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        const text = await res.text();
        if (!text) return null;
        const json = JSON.parse(text);
        return json;
    }

    async render(project, settings) {
        const token = this.authHelper.getToken();
        settings.enhanced = true;
        const res = await fetch(`${Config.host}/render/${project}`, {
            method: "POST", body: JSON.stringify(settings), headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }).catch(err => {
            return null;
        });
        const json = await res.json();
        return json;
    }

    async renderPreview(project) {
        const token = this.authHelper.getToken();
        const res = await fetch(`${Config.host}/render/${project}/preview`, {
            method: "POST", headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        const json = await res.json();
        return json;
    }

    async cancel(project) {
        const token = this.authHelper.getToken();
        await fetch(`${Config.host}/render/${project}`, {
            method: 'DELETE', headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => console.log(res));
    }

    async isFileExists(path) {
        const token = this.authHelper.getToken();
        if (path) {
            try {
                const res = await fetch(path,  {
                    method: "GET", headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then((res) => {
                    return res;
                }).catch((err) => {
                    return null;
                })
                    .then((data) => data)
                    .catch((err) => {
                        return null;
                    });
                if (!res || res.status !== 200) {
                    return null;
                }
                return res;
            } catch {
                return null;
            }
        } else {
            return null;
        }
    }
}

export default new RenderClient(authHelper);