import React from "react"
import Form from 'react-bootstrap/Form';
export function Checkbox({ text, id, value, setValue }) {

    const handleOnChange = (e) => {
        setValue(e.target.checked)
    }

    return (<Form.Check type={"switch"} id={id}>
        <Form.Check.Input 
            value={value}
            checked={value} onChange={handleOnChange} />
        <Form.Check.Label className="text-secondary" htmlFor={id}>{text}</Form.Check.Label>
    </Form.Check>
    )

}