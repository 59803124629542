import React from 'react';
import Layout from '../components/layout/Layout.jsx'
import "./App.scss"
import {
  Routes, Route, Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import ProjectList from "../pages/projects/ProjectList";
import NewProject from "../pages/projects/NewProject";
import Project from "../pages/projects/Project";
import LoginPage from '../pages/auth/LoginPage';
import SignUpPage from '../pages/auth/SignUpPage';
import authHelper from '../helpers/authHelper';
import ProfilePage from '../pages/user/ProfilePage';
import SharedVideoPage from '../pages/SharedVideoPage';
import Faq from '../pages/faq/Faq.jsx';
import '../i18n.js';

const ProtectedRoute = ({ anonym, children }) => {
  const token = authHelper.getToken();
  const location = useLocation();

  if (anonym) {
    if (token) {
      return <Navigate to="/" replace state={{ from: location }} />;
    }
    return children;
  }

  if (!token) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }
  return children;
};

function App() {
  React.useEffect(() => {
    const script = document.createElement("script")
    script.src =      "https://yastatic.net/share2/share.js"
    script.async = true
    script.crossOrigin = "anonymous"

    document.body.appendChild(script)

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script)
    }
  }, [])

  return (
    <>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route path="/" element={<ProtectedRoute><ProjectList /></ProtectedRoute>} />
          <Route path="/project" element={<ProtectedRoute>
            <NewProject />
          </ProtectedRoute>} />
          <Route path="/project/:id" element={<ProtectedRoute><Project /></ProtectedRoute>} />
          <Route path="/v/:share" element={<SharedVideoPage />} />
          <Route path="/login" element={<ProtectedRoute anonym={true}><LoginPage /></ProtectedRoute>} />
          <Route path="/sign-up" element={<ProtectedRoute anonym={true}><SignUpPage /></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
          <Route path="/faq" element={<Faq />}/> 
        </Route>
      </Routes>
    </>
  );
}

export default App;
