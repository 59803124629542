import { OperationResult } from "./common";

export class Login {
    userName = ""
    secret = ""
    lastName = ""
    firstName = ""
    phoneNumber = ""

    constructor(userName, secret, lastName, firstName, phoneNumber) {
        this.userName = userName;
        this.secret = secret;
        this.lastName = lastName;
        this.firstName = firstName;
        this.phoneNumber = phoneNumber;
    }
}



export class LoginResult extends OperationResult
{
    token = ""

    constructor() {
        this.token = ""
    }
}

export class RegisterResult extends OperationResult
{
    userId = ""

    constructor() {
        this.userId = ""
    }
}