export class VideoProject {
    constructor() {
        this.user = "";
        this.name = "";
        this.preset = "";
        this.fileNames = [];
        this.currentAudioTrack = 0;
        this.status = 0;
        this.settings = new Settings();
    }
}

export class Settings {
    constructor() {
        this.needSpeedUpAll = false;
        this.speedUpAll = 1;
        this.needSpeedUpSilence = false;
        this.isVertical = false;
        this.speedUpSilence = 2;
        this.switchTime = 5;
        this.audioSourceIndex = 0;
    }
}
