import { RegisterResult } from "../contract/project"
import { Config } from '../config'
import { Login, LoginResult } from "../contract/auth"

class AuthClient {

    /**
     * 
     * @param {Login} login 
     * @returns {LoginResult}
     */
    async login(login) {
        const res = await fetch(`${Config.authHost}/login`, {
            method: "Post", 
            body: JSON.stringify(login), 
            headers: {
                "Content-Type": "application/json"
            }
        });
        const json = await res.json();
        return json;
    }

    /**
     * 
     * @param {Login} register 
     * @returns {RegisterResult}
     */
    async register(register) {
        const res = await fetch(`${Config.authHost}/register`, {
            method: "Post", 
            body: JSON.stringify(register), 
            headers: {
                "Content-Type": "application/json"
            }
        });
        const json = await res.json();
        return json;
    }
}

export default new AuthClient();

