import Card from "../../components/card/Card";
import ListGroup from 'react-bootstrap/ListGroup';
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import projectClient from "../../helpers/projectClient";
import './project.css'
import { getPeriodText } from "../../helpers/timeHelper";
import { useTranslation } from "react-i18next";

const StatusBadge = ({ project }) => {

    if (project.status < 0) {
        return (<small className="badge rounded-pill bg-danger" data-status={project.status}>error</small>);
    }
    if (project.status == 0 || project.status == 2) {
        return (<small className="badge rounded-pill bg-info" data-status={project.status}>ready</small>);
    }
    if (project.status == 3) {
        if (project.positionInQueue > 0) {
            return (<span className="badge rounded-pill bg-warning" data-status={project.status}>
                in queue: {getPeriodText(project.lastActive)}
            </span>);
        }
        return (<span className="badge rounded-pill bg-warning" data-status={project.status}>
            {project.percentReady ? (project.percentReady * 100).toFixed(2) : 0}%
            </span>);
    }
    if (project.status == 4) {
        return (<span className="badge rounded-pill bg-success" data-status={project.status}>success</span>);
    }
    if (project.status == 5) {
        return (<span className="badge rounded-pill bg-secondary" data-status={project.status}>cancelling</span>);
    }
    if (project.status == 6) {
        return (<span className="badge rounded-pill bg-secondary" data-status={project.status}>cancelled</span>);
    }

    return (<span className="badge rounded-pill bg-secondary" data-status={project.status}>no files</span>);
}

export default function ProjectList() {
    const [items, setItems] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            let res = await projectClient.getAll();
            let arr = [];
            if (!res.length) {
                return;
            }
            res.forEach(item => {
                arr.push(new ProjectListItem(item));
            });
            setItems(arr);
        })();

        return () => {
            // this now gets called when the component unmounts
        }
    }, [])

    return (
        <Card>
            <div className="link text-secondary d-flex align-items-center justify-content-between">
                <h3>{t("yourProjects")}</h3>
                <Link className="btn btn-outline-info" to="project">{t("addFiles")}</Link>
            </div>
            <ListGroup>{items}</ListGroup>
        </Card>)
}

function ProjectListItem(project) {
    return <ListGroup.Item key={project.id} action href={`project/${project.id}`} className="d-flex w-100 justify-content-between bg-transparent border-0 link-opacity-10-hover px-0">
        <div className="d-flex w-100 justify-content-between">
            <h5 className="text-secondary">{project.name}</h5>
            <div>
                <StatusBadge project={project}></StatusBadge>
            </div>
        </div>
    </ListGroup.Item>
}