import { OperationResult } from "./common";

export class UserChangeSecret {
    oldSecret = ""
    newSecret = ""

    constructor(oldSecret, newSecret) {
        this.oldSecret = oldSecret;
        this.newSecret = newSecret;
    }
}

export class  User
{
    userId= "";
    userName= "";
    email= "";
    phoneNumber= "";
    firstName= "";
    secondName= "";
    roles= [];
    tenant= "";
}