
export function getPeriodText(lastDate) {
    const lastDateAsMilliseconds = Date.parse(lastDate);
    const diffMilliseconds = Date.now() - lastDateAsMilliseconds;

    if (diffMilliseconds <= 0) {
        return `just now`;
    }

    if (diffMilliseconds <= 60000) {
        const secondsAgo = Math.floor(diffMilliseconds / 1000);
        return `${secondsAgo} s. ago`;
    }

    if (diffMilliseconds <= 3600000) {
        const minsAgo = Math.floor(diffMilliseconds / 60000);
        return `${minsAgo} m. ago`;
    }

    if (diffMilliseconds <= 86400000) {
        const hoursAgo = Math.floor(diffMilliseconds / 3600000);
        return `${hoursAgo} h. ago`;
    }

    const daysAgo = Math.floor(diffMilliseconds / 86400000);

    return `${daysAgo} d. ago`;
}
