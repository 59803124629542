import React from "react";
import classNames from "classnames";

const wtf2 = (idx) => {
    debugger;
        };
export default function RadioInputValidate({ name, label, value, register, onChange, errors, validation, selected }) {
    const inputClassName = classNames({
        "form-check-input": true,
        "is-invalid": errors && errors[name]
    });

    return (
        <div className="form-check">
            {register &&
                <input checked={selected} className={inputClassName} type="radio" id={`radio-${name}-${value}`} value={value} name={name} onClick={onChange}
                    aria-invalid={errors && errors[name] ? "true" : "false"}
                    {...register(name, validation)} />  
            }

            {!register &&
                <input checked={selected} className={inputClassName} type="radio" id={`radio-${name}-${value}`} value={value} name={name} onClick={onChange} />
            }
            <label className="form-check-label text-secondary" htmlFor={`radio-${name}-${value}`}>{label}</label>
            {errors && errors[name] && <label className="text-danger invalid-feedback" htmlFor={`radio-${name}-${value}`}>{errors[name]?.message}</label>}
        </div>
    );
}

