import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import authHelper from "../../helpers/authHelper";
import logo from "../../assets/logo.png";
import { Telegram } from "react-bootstrap-icons";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavbarRoot from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import LanguageSelector from "../language/languageSelector";
import { useTranslation } from "react-i18next";

function Navbar() {
  const userContext = authHelper.getUserContext();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (size.width > 768 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);

  const menuToggleHandler = () => {
    setMenuOpen((p) => !p);
  };

  const onLogout = () => {
    authHelper.clearUserContext();
    navigate("/");
  };

  return (
    <NavbarRoot expand="lg" bg="blue" data-bs-theme="light" sticky="top" className="nav_animate">
      <Container>
        <NavbarRoot.Brand href="/">
          <img height={100} src={logo} alt="logo"/>
        </NavbarRoot.Brand>
        <NavbarRoot.Toggle aria-controls="basic-navbar-nav" />
        <NavbarRoot.Offcanvas
          id={`offcanvasNavbar-expand`}
          data-bs-theme="light"
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}>
              {t("navigation")}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {/* <Nav className="flex-grow-1 pe-3">
              <Nav.Link href="/">На главную</Nav.Link>
            </Nav> */}
            <Nav className="flex-grow-1 pe-3 justify-content-end">
              <Nav.Link href="/faq">{t("instruction")}</Nav.Link>
            </Nav>
          <Nav className="pe-3 justify-content-end">
              <Nav.Link href="https://t.me/videoneuron_chat" target='_blank'><Telegram size={24} className="tg_button"/></Nav.Link>
            </Nav>
            <Nav className="justify-content-end">
            <NavDropdown title={t("language")} id="basic-nav-dropdown">
            <LanguageSelector/>
            </NavDropdown>
            </Nav>
            {userContext &&
              <Nav className="justify-content-end pe-3">
                <NavDropdown title={userContext.email} id="basic-nav-dropdown">
                  <NavDropdown.Item href="/profile">{t("accountSettings")}</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={onLogout}>{t("exit")}</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            }
          </Offcanvas.Body>
        </NavbarRoot.Offcanvas>
      </Container>
    </NavbarRoot>
  );
}

export default Navbar;