import { ParagraphNode } from 'lexical';

export class CustomParagraphNode extends ParagraphNode {
	constructor(key, tooltip, startVideo, endVideo, focus = 'false') {
		super(key);
		this.__tooltip = tooltip;
		this.__startVideo = startVideo;
		this.__endVideo = endVideo;
		this.__focus = focus;
	}

	static getType() {
		return 'custom-paragraph';
	}

	static clone(node) {
		return new CustomParagraphNode(
			node.__key,
			node.__tooltip,
			node.__startVideo,
			node.__endVideo,
			node.__focus
		);
	}

	createDOM(config) {
		const dom = super.createDOM(config);

		dom.classList.add('custom-paragraph');
    dom.setAttribute('data-focus', this.__focus);

		if (this.__tooltip) dom.setAttribute('data-tooltip', this.__tooltip);
		if (this.__startVideo) dom.setAttribute('data-startVideo', this.__startVideo);
		if (this.__endVideo) dom.setAttribute('data-endVideo', this.__endVideo);

		return dom;
	}

	updateDOM(prevNode, dom, config) {
		const isUpdated = super.updateDOM(prevNode, dom, config);

		if (this.__focus) {
			dom.setAttribute('data-focus', this.__focus);
		}
		if (this.__tooltip) {
      dom.setAttribute('data-tooltip', this.__tooltip);
    }
    if (this.__startVideo) {
      dom.setAttribute('data-startVideo', this.__startVideo);
    }
    if (this.__endVideo) {
      dom.setAttribute('data-endVideo', this.__endVideo);
    }

		return isUpdated;
	}

	getFocus() {
    return this.__focus;
  }

	getStartVideo() {
		return this.__startVideo;
	}

	getEndVideo() {
		return this.__endVideo;
	}

	setFocus(focus) {
		const writableNode = this.getWritable();
		writableNode.__focus = focus;
	}

	setTooltip(tooltip) {
		const writableNode = this.getWritable();
		writableNode.__tooltip = tooltip;
	}

	setStartVideo(startVideo) {
		const writableNode = this.getWritable();
		writableNode.__startVideo = startVideo;
	}

	setEndVideo(endVideo) {
		const writableNode = this.getWritable();
		writableNode.__endVideo = endVideo;
	}

	static importJSON(serializedNode) {
		const node = $createCustomParagraphNode();

		node.__tooltip = serializedNode.tooltip;
		node.__startVideo = serializedNode.startVideo;
		node.__endVideo = serializedNode.endVideo;
		node.__focus = serializedNode.focus;

		return node;
	}

	exportJSON() {
		return {
			...super.exportJSON(),
			type: 'custom-paragraph',
			tooltip: this.__tooltip,
			startVideo: this.__startVideo,
			endVideo: this.__endVideo,
			focus: this.__focus
		};
	}
}

export function $createCustomParagraphNode() {
	const node = new CustomParagraphNode();

	return node;
}