import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import { $getRoot } from 'lexical';
import { Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

import { CustomParagraphNode } from '../CustomParagraphNode';

const UpdateParagraphsStatePlugin  = ({ setState, loader }) => {
  const [editor] = useLexicalComposerContext();
  const { t } = useTranslation();

  const handleChange = () => {
    editor.update(() => {
      const root = $getRoot();
      const children = root.getChildren();
  
      const paragraphs = children
        .filter(node => node instanceof CustomParagraphNode)
        .map(node => {
          return {
            text: node.getTextContent(),
            start: node.getStartVideo(),
            end: node.getEndVideo()
          };
      });

      setState(paragraphs);
    })
  }
   
  return (
    <div className="d-flex justify-content-around flex-wrap btn-container mt-4">
      <Button
        className="btn btn-width"
        variant={"outline-info"}
        size="lg"
        onClick={handleChange}
        disabled={loader}
      >
        {loader ? t("downloading") : t("subtitlesRender")}
      </Button>
    </div>
  )
};

export default UpdateParagraphsStatePlugin;
