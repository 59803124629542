import React from "react";
import { Carousel } from "react-bootstrap";
import share from "../../assets/share.jpg";
import interview from "../../assets/interview.png";
import speech from "../../assets/speech.png";
import single from "../../assets/single.png";
import settings from "../../assets/settings.png";
import intro from "../../assets/intro.png";
import { useTranslation } from "react-i18next";

export default function Faq() {
    const { t } = useTranslation();
  return (
    <Carousel data-bs-theme="dark" pause={'hover'}>
      <Carousel.Item interval={100000000000000}>
      <img
          className="d-block w-100 carousel-img"
          src={intro}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className="carousel-text">{t("start")}</h3>
          <p className="carousel-text">{t("prepareFormat")}</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={100000000000000}>
      <img
          className="d-block w-100 carousel-img"
          src={interview}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className="carousel-text">{t("threeCameras")}</h3>
          <p className="carousel-text">{t("threeCamerasDecs")}</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={100000000000000}>
      <img
          className="d-block w-100 carousel-img"
          src={speech}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className="carousel-text">{t("twoCameras")}</h3>
          <p className="carousel-text">{t("twoCamerasDesc")}</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={100000000000000}>
      <img
          className="d-block w-100 carousel-img"
          src={single}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className="carousel-text">{t("oneCamera")}</h3>
          <p className="carousel-text">
          {t("oneCameraDesc")}
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={100000000000000}>
      <img
          className="d-block w-100 carousel-img"
          src={settings}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className="carousel-text">{t("changeSettings")}</h3>
          <p className="carousel-text">
          {t("changeSettingsDesc")}
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={100000000000000}>
      <img
          className="d-block w-100 carousel-img"
          src={share}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className="carousel-text">{t("shareCarousel")}</h3>
          <p className="carousel-text">
          {t("shareCarouselDesc")}
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}
