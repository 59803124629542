import React from "react";
import "./ForgotPassword.scss";
import { useForm } from "react-hook-form"
import InputValidate from "../../components/input/InputValidate";
import { ButtonSubmit } from "../../components/button/ButtonSubmit";
import { useTranslation } from "react-i18next";
import { Login } from "../../contract/auth";
import userProfileClient from "../../helpers/userProfileClient";
import { useNavigate } from "react-router-dom";


export default function ForgotPassword ({active, setActive}) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        const login = new Login(data.email);
        await userProfileClient.resetPass(login);
    }

    return (
        <div className={active ? "modal active" : "modal"} onClick={() => setActive(false)}>
            <div className={active ? "modal_content active" : "modal_content"} onClick={e => e.stopPropagation()}>
                <div className="modal_wrapper">
                    <h2>{t("passwordChange")}</h2>
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-12 mb-4">
                    <InputValidate label={t("emailRequired")} name="email" register={register} errors={errors}
                        validation={{ required: t("emailRequired") }} />
                    </div>
                    <ButtonSubmit text={t("sendCode")}></ButtonSubmit>
                    </form>
                </div>
            </div>
        </div>
    )
}
