import { Config } from "../config";
import authHelper from "./authHelper";

class subtitlesClient {

     /**
     * @type {AuthHelper}
     */
     authHelper;

     /**
      * 
      * @param {AuthHelper} authHelper 
      */
     constructor(authHelper) {
         this.authHelper = authHelper;
     }

    async addSubtitles(projectId) {
        let subtitlesOptions = {
            projectId: projectId,
            shouldTranslate: false,
            languageCode: 'ru',
        }
       const token = this.authHelper.getToken();
       const res = await fetch(`${Config.subtitles}/api/Subtitles`, {
            method: "POST", body: JSON.stringify(subtitlesOptions), headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        const json = await res.json();

        return json;
    }

    async getSubtitles(id) {
        const token = this.authHelper.getToken();
        const res = await fetch(`${Config.subtitles}/api/Subtitles?renderProjectId=${id}`, {
            method: "GET", headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        const json = await res.json();

        return json;
    }

    async getSubtitlesWebVtt(id) {
        const token = this.authHelper.getToken();
        const res = await fetch(`${Config.subtitles}/api/Storage/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "text/vtt",
                Authorization: `Bearer ${token}`
            }
        });
    
        if (!res.ok) return null;
    
        const webVttText = await res.text();
    
        return webVttText;
    }

    async cutSegments(subtitleSegments) {
        const token = this.authHelper.getToken();
        const res = await fetch(`${Config.subtitles}/api/Subtitles/cutSegments`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(subtitleSegments)
        })
        const json = await res.json();

        return json;
    }

    async addXml(projectId) {
        let xmlOptions = {
            projectId: projectId,
        }
        const token = this.authHelper.getToken();
        const res = await fetch(`${Config.xml}/api/v1/fcpxml/${projectId}`, {
            method: "POST", body: JSON.stringify(xmlOptions), headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        const json = await res.json();
        return json;
    }

    async downloadFiles(id) {
        const token = this.authHelper.getToken();
        const res = await fetch(`${Config.host}/storage/${id}/files`, {
            method: "GET", headers: {
                "Content-Type": "application/octet-stream",
                "Content-Disposition": `attachment; filename=project_files_${id}.zip`,
                Authorization: `Bearer ${token}`
            }
        });

        const blob = await res.blob();
        return blob;
    }
}

export default new subtitlesClient(authHelper);