import React from "react";


export function FormErrors({ errors, formErrors, successful, defaultMessage }) {
    if (successful) {
        return (
            <div className="d-flex  valid-feedback" role="alert">{defaultMessage}</div>
        )
    } 


    return errors != null && Object.keys(errors).length > 0 ?
        <div>
            {Object.keys(errors).map((k) => <div className="d-flex invalid-feedback" role="alert">{errors[k]}</div>)}
        </div>
        :
        <></>

}
