import Card from "../../components/card/Card";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import InputValidate from "../../components/input/InputValidate";
import { FormErrors } from "../../components/input/FormErrors";
import { ButtonSubmit } from "../../components/button/ButtonSubmit";
import userProfileClient from "../../helpers/userProfileClient";
import { User, UserChangeSecret } from "../../contract/userProfile";
import { useTranslation } from "react-i18next";

export default function ProfilePage() {
    const {
        register: registerAccountSettings,
        handleSubmit: handleSubmitAccountSettings,
        formState: { errors: accountSettingsFormErrors },
        setValue: accountSettingsSetValue
    } = useForm();

    const {
        register: registerForChangePassword,
        handleSubmit: handleSubmitForChangePassword,
        formState: { isSubmitSuccessful: changePasswordSuccessful, errors: changePasswordFormErrors },
        reset
    } = useForm();

    const [accountSettingsErrors, setAccountSettingsErrors] = useState({});
    const [isAccountSettingsSuccess, setAccountSettingsSuccess] = useState(false);

    const [changePasswordErrors, setChangePasswordErrors] = useState({});
    const [isChangePasswordSuccess, setChangePasswordSuccess] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            let profileData = await userProfileClient.get();
            if (profileData == null) {
                return;
            }
            accountSettingsSetValue("firstName", profileData.firstName);
            accountSettingsSetValue("secondName", profileData.secondName);
            accountSettingsSetValue("phone", profileData.phoneNumber);
        })();

        return () => {
        };
    }, []);

    const onSubmitAccountSettings = async (data) => {
        const userUpdate = new User();
        userUpdate.firstName = data.firstName;
        userUpdate.secondName = data.secondName;
        userUpdate.phoneNumber = data.phone;

        const result = await userProfileClient.update(userUpdate);

        if (result.successful) {
            setAccountSettingsErrors({});
        } else {
            setAccountSettingsErrors(result.errors);
        }
        setAccountSettingsSuccess(result.successful);
    }

    const onSubmitChangePassword = async (data) => {
        const userChangeSecret = new UserChangeSecret(data.oldPassword, data.newPassword);
        const result = await userProfileClient.changeSecretAsync(userChangeSecret);

        if (result.successful) {
            setChangePasswordErrors({});
            reset();
        } else {
            setChangePasswordErrors(result.errors);
        }
        setChangePasswordSuccess(result.successful);
    }

    const handleChangeAccount = () => {
        setAccountSettingsSuccess(false);
    }

    const handleChangePassword = () => {
        setChangePasswordSuccess(false);
    }

    return (
        <Card>
            <div className="link text-secondary d-flex align-items-center justify-content-center">
                <h3>{t("accountSettings")}</h3>
            </div>

            <form onSubmit={handleSubmitAccountSettings(onSubmitAccountSettings)} onChange={handleChangeAccount} className="row g-3">
                <div className="col-6">
                    <InputValidate label={t("firstName")} name="firstName" register={registerAccountSettings} />
                </div>
                <div className="col-6">
                    <InputValidate label={t("secondName")} name="secondName" register={registerAccountSettings} />
                </div>
                <div className="col-12">
                    <InputValidate label={t("phone")} name="phone" register={registerAccountSettings} errors={accountSettingsFormErrors}
                        validation={{
                            pattern: {
                                value: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
                                message: t("wrongFormat"),
                            },
                        }} />
                </div>
                <FormErrors errors={accountSettingsErrors} successful={isAccountSettingsSuccess} defaultMessage={t("saveSuccess")} />
                <div className="d-flex justify-content-center gap-2">
                    <ButtonSubmit text={t("save")}></ButtonSubmit>
                </div>
            </form>

            <div className="mt-5 link text-secondary d-flex align-items-center justify-content-center">
                <h3>{t("changePassword")}</h3>
            </div>

            <form onSubmit={handleSubmitForChangePassword(onSubmitChangePassword)} onChange={handleChangePassword} className="row g-3">
                <div className="col-6">
                    <InputValidate label={t("oldPass")} type="password" name="oldPassword" required register={registerForChangePassword}
                        errors={changePasswordFormErrors}
                        validation={{ required: t("oldPassRequired") }} />
                </div>
                <div className="col-6">
                    <InputValidate label={t("newPass")} type="password" name="newPassword"
                        errors={changePasswordFormErrors} register={registerForChangePassword} required
                        validation={{ required: t("newPassRequired"), minLength: { value: 10, message: t("minLength") } }} />
                </div>
                <FormErrors errors={changePasswordErrors} formErrors={changePasswordFormErrors}
                    successful={isChangePasswordSuccess} defaultMessage={t("passChanged")} />
                <div className="d-flex justify-content-center gap-2">
                    <ButtonSubmit text={t("changePassword")}></ButtonSubmit>
                </div>

            </form>
        </Card>
    )

}