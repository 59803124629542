import React from "react";
import { Button } from "react-bootstrap";

export function ButtonSubmit(prop) {
    const btn = () => {
        return (
            prop.disabled ? <Button className="btn-width" variant="outline-info" size="lg" onClick={prop.onClick} type="submit" disabled>{prop.text}</Button> :
                <Button className="btn-width" variant="outline-info" size="lg" onClick={prop.onClick} type="submit">{prop.text}</Button>
        )
    };
    return btn();
}