
import { useCallback, useEffect } from 'react';

import {
  KEY_ENTER_COMMAND,
  COMMAND_PRIORITY_LOW,
  $getSelection,
  $isRangeSelection
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getNearestNodeOfType } from '@lexical/utils';

import { CustomParagraphNode } from '../CustomParagraphNode';

const PreventNewParagraphPlugin = () => {
  const [editor] = useLexicalComposerContext();

  const onEnterPress = useCallback((event) => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const paragraphNode = $getNearestNodeOfType(anchorNode, CustomParagraphNode);

      if (paragraphNode) {
        event.preventDefault();

				return true;
      }
    }

    return false;
  }, []);

  useEffect(() => {
    const unregisterEnterCommand = editor.registerCommand(
      KEY_ENTER_COMMAND,
      onEnterPress,
      COMMAND_PRIORITY_LOW,
    );

    return () => {
      unregisterEnterCommand();
    };
  }, [editor, onEnterPress]);

  return null;
};

export default PreventNewParagraphPlugin;
