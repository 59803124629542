import React, { useRef, useEffect, useState } from "react"
import RangeSlider from 'react-range-slider-input';
import './slider.scss';

export default function Slider({ value, onChange, min, max, step, format, isRange = false }) {

    const formatValue = (value) => format ? (format(value)) : value;

    return (
        <>
            <div className="d-flex justify-content-between">
                <span className="text-secondary">{formatValue(isRange ? value[0] : value)}</span>
                {isRange && <span className="text-secondary">{formatValue(value[1])}</span>}
            </div>

            {isRange &&
                <RangeSlider min={min} max={max} step={step} value={value} onInput={onChange} />
            }

            {!isRange &&
                <RangeSlider min={min} max={max} step={step} value={[min, value]} onInput={(v) => onChange(v[1])}
                    className="single-thumb"
                    thumbsDisabled={[true, false]}
                    />
            }

            <div className="d-flex justify-content-between">
                <span className="text-secondary">{formatValue(min)}</span><span className="text-secondary">{formatValue(max)}</span>
            </div>
        </>
    );
}