import { Settings } from "../contract/project"
import { Config } from '../config'
import authHelper from "./authHelper";

class ProjectClient {

    /**
     * @type {AuthHelper}
     */
    authHelper;

    /**
     * 
     * @param {AuthHelper} authHelper 
     */
    constructor(authHelper) {
        this.authHelper = authHelper;
    }

    async getAll() {
        const token = this.authHelper.getToken();
        const res = await fetch(`${Config.host}/project`, {
            method: "GET", headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        const json = await res.json()
        return json
    }


    async get(project) {
        const token = this.authHelper.getToken();
        const res = await fetch(`${Config.host}/project/${project}`, {
            method: "GET", headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        if (!res.ok) {
            return null;
        }

        const json = await res.json();
        return json;
    }

    async getShare(share) {
        const res = await fetch(`${Config.host}/project/share/${share}`, {
            method: "GET", headers: {
                "Content-Type": "application/json"
            }
        });

        if (!res.ok) {
            return null;
        }

        const json = await res.json();
        return json;
    }

    async remove(project) {
        const token = this.authHelper.getToken();
        await fetch(`${Config.host}/project/${project}`, {
            method: 'DELETE', headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => console.log(res))
    }
}

export default new ProjectClient(authHelper);