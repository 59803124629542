import { useCallback, useEffect } from 'react';

import {
	$getSelection,
	$isRangeSelection,
	KEY_ARROW_UP_COMMAND,
	KEY_ARROW_DOWN_COMMAND,
	COMMAND_PRIORITY_LOW
} from "lexical";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getNearestNodeOfType } from '@lexical/utils';

import { CustomParagraphNode } from '../CustomParagraphNode';

const ArrowNavigationPlugin = () => {
  const [editor] = useLexicalComposerContext();

  const onArrowUpPress = useCallback((event) => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const paragraphNode = $getNearestNodeOfType(anchorNode, CustomParagraphNode);

      if (paragraphNode) {
        const previousNode = paragraphNode.getPreviousSibling();

        if (previousNode instanceof CustomParagraphNode) {
          event.preventDefault();
          previousNode.selectEnd();

          return true;
        }
      }
    }

    return false;
    }, []);

  const onArrowDownPress = useCallback((event) => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const paragraphNode = $getNearestNodeOfType(anchorNode, CustomParagraphNode);

      if (paragraphNode) {
        const nextNode = paragraphNode.getNextSibling();

        if (nextNode instanceof CustomParagraphNode) {
          event.preventDefault();
          nextNode.selectStart();

          return true;
        }
      }
    }

    return false;
  }, []);

  useEffect(() => {
    const unregisterArrowUp = editor.registerCommand(
      KEY_ARROW_UP_COMMAND,
      onArrowUpPress,
      COMMAND_PRIORITY_LOW,
    );

    const unregisterArrowDown = editor.registerCommand(
      KEY_ARROW_DOWN_COMMAND,
      onArrowDownPress,
      COMMAND_PRIORITY_LOW,
    );

    return () => {
      unregisterArrowUp();
      unregisterArrowDown();
    };
  }, [editor, onArrowDownPress, onArrowUpPress]);

  return null;
};

export default ArrowNavigationPlugin;