import { useCallback, useEffect } from 'react';

import {
	$getSelection,
	$isRangeSelection,
	KEY_DELETE_COMMAND,
	KEY_BACKSPACE_COMMAND,
	COMMAND_PRIORITY_LOW
} from "lexical";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getNearestNodeOfType } from '@lexical/utils';

import { CustomParagraphNode } from '../CustomParagraphNode';

const DeleteParagraphPlugin = () => {
  const [editor] = useLexicalComposerContext();

  const onDelete = useCallback((event) => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const paragraphNode = $getNearestNodeOfType(anchorNode, CustomParagraphNode);

      if (paragraphNode) {
        event.preventDefault();

        const nextNode = paragraphNode.getNextSibling();
        const previousNode = paragraphNode.getPreviousSibling();

        paragraphNode.remove();

        if (nextNode instanceof CustomParagraphNode) {
          nextNode.selectStart();
        } else if (previousNode instanceof CustomParagraphNode) {
          previousNode.selectEnd();
        }

        return true;
      }
    }

    return false;
  }, []);

  useEffect(() => {
    const unregister = editor.registerCommand(
      KEY_DELETE_COMMAND,
      onDelete,
      COMMAND_PRIORITY_LOW
    );

    const unregisterBackspace = editor.registerCommand(
      KEY_BACKSPACE_COMMAND,
      onDelete,
      COMMAND_PRIORITY_LOW
    );

    return () => {
      unregister();
      unregisterBackspace();
    };
  }, [editor, onDelete]);

  return null;
};

export default DeleteParagraphPlugin;