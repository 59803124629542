import './index.scss';

import { useState, useEffect } from "react";
import ReactPlayer from 'react-player';

const addTextTrack = (videoNode, trackLang, subtitlesUrl) => {
	if (!videoNode || !subtitlesUrl) return;

  const existingTracks = videoNode.querySelectorAll(`track[srclang="${trackLang}"]`);

  existingTracks.forEach(track => {
    videoNode.removeChild(track);
  });

  const track = document.createElement("track");

  track.kind = "subtitles";
  track.srclang = trackLang;
  track.src = subtitlesUrl;
  track.default = true;

  videoNode.appendChild(track);

  const textTracks = videoNode.textTracks;

  for (let i = 0; i < textTracks.length; i++) {
    textTracks[i].mode = textTracks[i].language === trackLang ? "showing" : "hidden";
  }
};

const VideoPlayerNew2 = ({ videoId, videoRef, src, subtitlesUrl, updateSubtitles }) => {
	const [trackLang, setTrackLang] = useState("RU");

	useEffect(() => {
		if(updateSubtitles) {
			updateSubtitles();
		}
	}, [updateSubtitles]);

  useEffect(() => {
    if (subtitlesUrl) {
      const videoNode = videoRef.current?.getInternalPlayer();

      if (videoNode) {
        addTextTrack(videoNode, trackLang, subtitlesUrl);
      }
    }
  }, [subtitlesUrl, trackLang, videoRef]);

	return (
		<div className="video-container">
			<div className="video-overlay" />
			<ReactPlayer
				id={videoId}
				ref={videoRef}
				url={src}
				className="video-player"
				width='100%'
				height='100%'
				controls
				playsinline
				config={{
					file: {
						attributes: {
							crossOrigin: "true",
						},
						tracks: []
					}
				}}
			/>
		</div>
	);
}

export default VideoPlayerNew2;