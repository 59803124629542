import React from "react";
import classNames from "classnames";

export default function InputValidate({ name, label, register, type, errors, validation }) {
    const inputClassName = classNames({
        "form-control": true,
        "is-invalid": errors && errors[name]
    });

    return (
        <div className="form-floating">
            {register &&
                <input className={inputClassName} type={type} id={"id-" + name} {...register(name, validation)}
                    placeholder={label}
                    aria-invalid={errors && errors[name] ? "true" : "false"}
                />
            }
            {!register &&
                <input className={inputClassName} type={type} id={"id-" + name} />
            }
            {(errors == null || !errors.hasOwnProperty(name)) && <label htmlFor={"id-" + name} > {label}</label>}
            {errors && errors[name] && <label className="text-danger invalid-feedback" htmlFor={"id-" + name}>{errors[name]?.message}</label>}
        </div>);
}


