import React from "react";
import shareClient from "../../../helpers/shareClient";
import { Share, ShareFill, Clipboard } from "react-bootstrap-icons";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Stack from 'react-bootstrap/Stack';

export default class ShareVideo extends React.Component {
    constructor(props) {
        super(props);
        this.share = null;
        this.shareInputRef = React.createRef();

        this.state = {
            shareLink: "",
            isShared: false,
            isShareCreated: false,
            projectId: props.projectId || null,
        };
    }

    shareVideo = async () => {
        if (this.state.projectId == null) {
            return;
        }

        var share = await shareClient.create(this.state.projectId, this.props.videoType).catch(err => null);

        if (share == null) {
            return;
        }

        const shareLink = this.getShareLink(share.share);

        this.setState({
            isShared: true,
            isShareCreated: true,
            shareLink: shareLink
        });

        try {
            if (navigator.clipboard && window.isSecureContext) {
                await navigator.clipboard.writeText(shareLink);
            }
        }catch{}
    };

    copySharedVideo = async () => {
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(this.state.shareLink);
        }

        if (this.shareInputRef.current == null) {
            return;
        }
        this.shareInputRef.current.select();
    };

    removeShare = async () => {
        if (!this.state.isShared) {
            return;
        }

        await shareClient.remove(this.state.projectId);

        this.setState({ shareLink: "" });
        this.setState({ isShared: false });
    };

    getShareLink = (share) => `${window.location.origin}/v/${share}`;

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.videoType !== this.props.videoType) {
            
            
            this.setState({
                isShared: false,
                shareLink: '',
                share: null
            });

            //this.shareVideo();
            
            this.test();
        }
    }

    test = () => {
        if (this.getSharePromise) {
            return;
        }

        if (!this.share) {
            this.getSharePromise = shareClient.get(this.state.projectId, this.props.videoType).then((res) => {
                this.share = res;

                if (this.share == null) {
                    return;
                }

                this.setState({
                    isShared: true,
                    shareLink: this.getShareLink(this.share.share)
                });

            }).catch(err => null);
        }
    }

    componentDidMount = () => {
        if (this.getSharePromise) {
            return;
        }

        if (!this.share) {
            this.getSharePromise = shareClient.get(this.state.projectId, this.props.videoType).then((res) => {
                this.share = res;

                if (this.share == null) {
                    return;
                }

                this.setState({
                    isShared: true,
                    shareLink: this.getShareLink(this.share.share)
                });

            }).catch(err => null);
        }
    }



    renderShare = () => {
        const share = (<div id="ya" className="me-1" />);

        if (window.Ya) {
            const yaShare = window.Ya.share2('ya', {
                theme: {
                    services: 'telegram,whatsapp,vkontakte,messenger',
                    popupDirection: "auto",
                    copy: "last",
                    limit: 2,
                    size: "m",
                    curtain: true,
                    bare: false,
                    colorScheme: "blackwhite"
                },
                content: { url: this.state.shareLink, title: "Share video" }
            });

            if (!this.state.isShared) {
                if (yaShare) {
                    yaShare.destroy();
                }

                return share;
            }
        }

        return share;
    }

    popover = () => {
        return (<Popover id="popover-basic">
            <Popover.Header as="h3">{"Shared video"}</Popover.Header>
            <Popover.Body variant="dark">
                <InputGroup className="mb-3">
                    <Form.Control defaultValue={this.state.shareLink} readOnly={true} ref={this.shareInputRef} />
                    <Button variant="dark" onClick={() => this.copySharedVideo()}><Clipboard /></Button>
                </InputGroup>
                <Stack direction="horizontal" gap={3}>
                    <div className="ms-auto">
                        <Button variant="danger" onClick={this.removeShare}>Remove share</Button>
                    </div>
                </Stack>
            </Popover.Body>
        </Popover>)
    };

    render() {
        return (
            <div className="d-flex justify-content-end" data-test={this.state.videoType}>
                {this.renderShare()}

                {this.state.isShared &&
                    <OverlayTrigger trigger="click" placement="bottom" overlay={this.popover()}>
                        <Button variant="danger" data-bs-custom-class="custom-popover"><Share /></Button>
                    </OverlayTrigger>
                }

                {!this.state.isShared &&
                    <button type="button" className="btn btn-secondary" onClick={this.shareVideo}>
                        <ShareFill />
                    </button>
                }

            </div>
        )
    }
}