import Card from "../../components/card/Card";
import Input from "../../components/input/Input";
import FileInputValidate from "../../components/input/FileInputValidate";
import DropdownList from "../../components/dropdown/DropdownList";
import { ButtonSubmit } from "../../components/button/ButtonSubmit"

import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { StorageClient } from "../../helpers/StorageClient";
import './project.css'
import { Settings } from "../../contract/project";
import authHelper from "../../helpers/authHelper";
import InputValidate from "../../components/input/InputValidate"
import { useForm } from "react-hook-form"
import RadioInputValidate from "../../components/input/RadioInputValidate";
import { useTranslation } from "react-i18next";
import UploadFile from "../../components/uploadFile";
import { Button } from 'react-bootstrap';
import { Plus, Trash } from 'react-bootstrap-icons';

export default function NewProject() {
    let { id } = useParams("id");
    const {
        register,
        unregister,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [files, setFiles] = useState(new Map);
    const [preset, setPreset] = useState(0);
    const [track, setTrack] = useState(0);
    const [progress, setProgress] = useState(0);
    const [isUploading, setUploading] = useState(false);
    // const [chooseFiles, setChooseFiles] = useState({});
    const presets = ['single', 'speech', 'interview'];
    const [isProjCreating, seIsProjCreating] = useState(false);
    const [presetClicked, setPresetClicked] = useState(false);
    const [videoClicked, setVideoClicked] = useState(false);
    const [isShown, setIsShown] = useState(true);
    const [isVideoShown, setIsVideoShown] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});

    // const presetsTranslated =
    // presets.map((preset) => {
    // if (preset === 'interview') {
    //    return t("threeCameras")
    // } else if (preset === 'speech') {
    //     return t("twoCameras")
    // } else if (preset === 'single') {
    //     return t("oneCamera")
	// }
    // })

    const upload = async (data) => {
        const uploader = new StorageClient(authHelper);

        uploader.OnProgress = setProgress;
        uploader.OnLoad = setUploading;
        uploader.OnComplete = onFinsh;

        uploader.project.name = data.name;
        uploader.project.preset = presets[preset];
        const stg = new Settings();
        stg.audioSourceIndex = Number(track);
        uploader.project.settings = stg;
        uploader.project.fileNames = {};
        
        const fs = []
        files.forEach((_fs, key) => {
            fs.push(..._fs);
            const mapped = [..._fs].map(f => f.name);
            uploader.project.fileNames[key] = mapped;
        });

        uploader.upload(fs);
        seIsProjCreating(true);
    }

    const handleOnChange = (files, id) => {
        // chooseFiles[id] = files;
        // setChooseFiles(chooseFiles);

        setFiles((prevState) => {
            const newMap = new Map(prevState)
            newMap.set(id, files)
            return newMap
        });
    }

    const handleRemoveFile = (fileName, id) => {
        setFiles((prevFiles) => {
            const newMap = new Map(prevFiles);
            const newFiles = newMap.get(id);

            if (!newFiles) return prevFiles;

            const updateFiles = Array.from(newFiles).filter((file) => file.name !== fileName);
            
            if (updateFiles.length > 0) newMap.set(id, updateFiles);
            else newMap.delete(id);

            return newMap;
        });
    };

    const handleRemoveUpload = (id) => {
        setFiles((prevFiles) => {
            const newMap = new Map(prevFiles);
            newMap.delete(id);
            return newMap;
        });
    };

    const validateFiles = () => {
        const errors = {};
        let isValid = true;

        projectVideos.forEach(video => {
            if (!files.has(video.camera) || files.get(video.camera).length === 0) {
                errors[video.camera] = t("oneOreMore");
                isValid = false;
            }
        });

        setErrorMessage(errors);
        return isValid;
    };

    const onSubmit = (data) => {
        if (validateFiles()) {
            upload(data);
        }
    }

    const onFinsh = (uploadedProject) => {
        navigate(`/project/${uploadedProject.id}`);
    }

    // const videoValidator = (chooseFiles, id) => {
    //     return {
    //         "validate": {
    //             "video": () => (chooseFiles && chooseFiles.hasOwnProperty(id) && chooseFiles[id]) || t("oneOreMore")
    //         }
    //     }
    // };

    const projectVideosInventory = [{
        'camera': 0,
        'presets': [0],
        'name': "generalPlan"
    }, {
        'camera': 1,
        'presets': [0,1],
        'name': "secondPlan"
    }, {
        'camera': 2,
        'presets': [0,1,2],
        'name': "thirdPlan"
    }];

    const filterProjectVideos = (presetIdx) => {
        const res = [];

        for(const pv of projectVideosInventory.slice(0, presetIdx + 1)) {
            const clone = {...pv};
            res.push(clone);
        }

        return res;
    };

    const doSetPreset = (presetIdx) => {
        const filtered = filterProjectVideos(presetIdx);
        setProjectVideos(filtered);
        setPreset(presetIdx);
    };

    const [projectVideos, setProjectVideos] = useState(filterProjectVideos(0));

    const handleAddVideo = () => {
        if (preset >= 2) return;

        doSetPreset(preset + 1);
    };

    const handleRemoveVideo = (id) => {
        if (preset <= 0) return;

        handleRemoveUpload(id);
        doSetPreset(preset - 1);
    };

    // const handlePresetClicked = () => {
    //     setPresetClicked(t("chooseVideofile"))
    //     setIsShown(true)
    // }

    const handleVideoClicked = () => {
            setPresetClicked(false)
            setVideoClicked(t("chooseAudioAndCreate"))
            setIsVideoShown(true)
    }

    return <Card>
        {!videoClicked ?
        <div className="link text-secondary d-flex align-items-center justify-content-between pb-3 flex-wrap">
            <h3>{t("newProject")}</h3>
            {/* {!presetClicked ? */}
            {/* // <h5 className="project_faq">{t("scenarioNextStep")}</h5> */}
            <h5 className="project_faq">{t("nextStep")} {t("chooseVideofile")}</h5>
            {/* : */}
            {/* <h5 className="project_faq">{t("nextStep")} {presetClicked}</h5> */}
            {/* } */}
        </div>
        :
        <div className="link text-secondary d-flex align-items-center justify-content-between pb-3 flex-wrap">
            <h3>{t("newProject")}</h3>
            {videoClicked &&
            <h5 className="project_faq">{t("nextStep")} {videoClicked}</h5>
            }
        </div>
        }

        <form onSubmit={handleSubmit(onSubmit)} disabled={isUploading} className="row g-3">
            {/* <div className="col-12">
                <InputValidate label="Название проекта" name="name" type="input" errors={errors} register={register} required
                    validation={{ required: "Project name is required" }} />
            </div> */}

            {isShown &&
            <>
                <div className="d-flex flex-column gap-3">
                    {
                        projectVideos.map((v, i) => {
                            return (
                            <div key={v.camera} className="d-flex flex-column gap-3 bg-white p-3 rounded position-relative">
                                <label>{t(v.name)}</label>
                                <div className="col-12 " onClick={handleVideoClicked}>
                                    <UploadFile
                                        id={v.camera}
                                        handleOnChange={handleOnChange}
                                        handleDeleteFiles={handleRemoveFile}
                                        errorMessage={errorMessage[v.camera]}
                                        handleSetErrorMessage={setErrorMessage}
                                    />
                                    {/* <FileInputValidate
                                        id={v.camera}
                                        handleOnChange={handleOnChange}
                                        name={`video-${i}`}
                                        label={v.name}
                                        errors={errors}
                                        register={register}
                                        unregister={unregister}
                                        setError={setError}
                                        validation={videoValidator(chooseFiles, v.camera)}
                                    /> */}
                                    </div>
                                {/* {isVideoShown && */}
                                <div className="col-12 ">
                                    <RadioInputValidate
                                        selected={track == v.camera}
                                        onChange={evt => setTrack(evt.target.value)}
                                        name="track"
                                        value={v.camera} 
                                        register={register}
                                        errors={errors} 
                                        validation={{ required: t("needToChoose") }} label={t("audio")}
                                    />
                                </div>
                                {/* } */}
                				{ v.camera > 0 && v.camera === projectVideos.length - 1 &&
                                    <Trash role="button" className="position-absolute top-0 end-0 cursor-pointer mt-3 me-3"
                                        width={16} height={20} onClick={() => handleRemoveVideo(v.camera)}
                                    />
                                }
                            </div>
                            )
                        })
                    }
                    <div className="col-12">
                        {
                        preset < 2  &&
                            <Button variant={"outline-info"} className="w-100" type="button" onClick={handleAddVideo}>
                                <Plus width={24} height={24} />
                            </Button>
                        }
                    </div>
                </div>

                {
                    isUploading &&
                    <div className="d-grid gap-2">
                        <div className="progress" role="progressbar" aria-label="Warning example" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
                            <div className="progress-bar text-bg-dark" style={{ width: `${progress}%` }}>{progress.toLocaleString(undefined, { maximumFractionDigits: 2 })}%</div>
                        </div>
                    </div>
                }

                <div className="d-flex justify-content-center gap-2">
                    <ButtonSubmit disabled={isProjCreating} text={t("createProject")}></ButtonSubmit>
                </div>
            </>
            }
        </form>
    </Card>
}
