import { useCallback, useEffect } from 'react';

import { $createParagraphNode, $createTextNode, $getRoot } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

function AddTextPlugin({ segments }) {
  const [editor] = useLexicalComposerContext();

  const addText = useCallback(() => {
    editor.update(() => {
      const root = $getRoot();
      root.clear();

      segments.forEach((item) => {
        let paragraph = $createParagraphNode();
        let text = $createTextNode(item.text);

        paragraph.setTooltip(item.start.slice(0, 8));
        paragraph.setStartVideo(item.start);
        paragraph.setEndVideo(item.end);

        root.append(paragraph.append(text));
      });
    });
  }, [editor, segments]);

  useEffect(() => {
    if (segments?.length > 0) {
      addText();
    }
  },[addText, segments])

  return null;
}

export default AddTextPlugin;