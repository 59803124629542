import { Config } from '../config';
import authHelper from "./authHelper";

class ShareClient {
    /**
 * @type {AuthHelper}
 */
    authHelper;

    /**
     *
     * @param {AuthHelper} authHelper
     */
    constructor(authHelper) {
        this.authHelper = authHelper;
    }

    async get(projectId, videoType) {
        const token = this.authHelper.getToken();
        try {
            const res = await fetch(`${Config.host}/share/${projectId}/${videoType}`, {
                method: "GET", headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            }).catch(err => {
                return null;
            });

            if (!res.ok) {
                return null;
            }

            const json = await res.json();
            return json;
        } catch {
            return null;
        }
    }

    async create(projectId, videoType) {
        const token = this.authHelper.getToken();
        try {
            const res = await fetch(`${Config.host}/share/${projectId}/${videoType}`, {
                method: "POST", headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            }).catch(err => {
                return null;
            });
            const json = await res.json();
            return json;
        } catch {
            return null;
        }
    }

    async remove(projectId) {
        const token = this.authHelper.getToken();
        await fetch(`${Config.host}/share/${projectId}`, {
            method: 'DELETE', headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
    }
}


export default new ShareClient(authHelper);
