import './index.scss';

import { useState } from 'react';
import { useTranslation } from "react-i18next";

import { FileEarmarkPlus, FileEarmarkCheck, X } from 'react-bootstrap-icons';

const UploadFile = ({ id, handleOnChange, handleDeleteFiles, errorMessage, handleSetErrorMessage }) => {
	const [filesPaths, setFilesPaths] = useState({});
	const [isDragging, setIsDragging] = useState(false);
	
	const { t } = useTranslation();

	const validateFiles = (files) => {
		return Array.from(files).every(file => file.type.startsWith('video/'));
	};

	const handleDrop = (event) => {
		event.preventDefault();

		if (!event.dataTransfer) return;

		const newFiles = event.dataTransfer.files;

		if (!validateFiles(newFiles)) {
			handleSetErrorMessage(prevErrors => ({
				...prevErrors,
				[id]: 'onlyVideo'
			}));
	
			setIsDragging(false);

			return;
		}

		const newFilesPaths = {};

		Array.from(newFiles).forEach((file, i) => {
			newFilesPaths[file.name] = file;
		});

		setFilesPaths((prevFilesPaths) => ({ ...prevFilesPaths, ...newFilesPaths }));
		handleOnChange(newFiles, id);
		setIsDragging(false);
		handleSetErrorMessage(prevErrors => ({
			...prevErrors,
			[id]: ''
		}));
	};

	const handleFiles = (event) => {
		event.preventDefault();

		const newFiles = event.currentTarget.files;

		if (!validateFiles(newFiles)) {
			handleSetErrorMessage(prevErrors => ({
				...prevErrors,
				[id]: 'onlyVideo'
			}));

			setIsDragging(false);

			return;
		}

		const newFilesPaths = {};

		for (let i = 0; i < newFiles.length; i++) {
			const file = newFiles[i];
			newFilesPaths[file.name] = file;
		}

		setFilesPaths((prevFilesPaths) => ({ ...prevFilesPaths, ...newFilesPaths }));
		handleOnChange(newFiles, id);
		handleSetErrorMessage(prevErrors => ({
			...prevErrors,
			[id]: ''
		}));
	};

	const handleDragOver = (event) => event.preventDefault();

	const handleRemoveFile = (event, fileName) => {
		event.preventDefault();

		setFilesPaths((prevFilesPaths) => {
			const newFilesPaths = { ...prevFilesPaths };
			
			delete newFilesPaths[fileName];
			return newFilesPaths;
		});
		
		return handleDeleteFiles(fileName, id);
	};

	return(
		<>
			<input
				id={"file-" + id}
				className="upload"
				type="file"
				accept='video/*'
				multiple
				hidden
				disabled={Object.keys(filesPaths).length > 0}
				onChange={handleFiles}
			/>
			<label
				htmlFor={"file-" + id}
				className={`upload-container ${Object.keys(filesPaths).length > 0 ? 'upload-container_load' : ''} ${isDragging ? 'upload-container_active' : ''}`}
				onDrop={handleDrop}
				onDragOver={handleDragOver}
				onDragEnter={() => setIsDragging(true)}
				onDragLeave={() => setIsDragging(false)}
			>
				{
					Object.keys(filesPaths).length > 0 ? (
						<>
							{Object.keys(filesPaths).map((fileName) => (
								<div className="upload-container__info" key={fileName}>
									<FileEarmarkCheck className="upload-container__info-icon" />
									<p className="upload-container__info-text">{fileName}</p>
									<X className="upload-container__info-clear" onClick={(e) => handleRemoveFile(e, fileName)} />
								</div>
							))}
						</>
					) : (
						<div className="upload-container__info" inert="true">
							<FileEarmarkPlus className="upload-container__info-icon" />
							<p className="upload-container__info-text">
								{t('dragAndDrop')}
							</p>
						</div>
					)
				}
			</label>
			{errorMessage && <span className="text-danger d-inline-flex mt-1">{t(errorMessage)}</span>}
		</>
	);
}

export default UploadFile;