import React from "react";

export default class Input extends React.Component {

    constructor(props) {
        super(props)
        this.state = {value: "", type: "text"}

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }

    render() {
        return <div className="form-floating">
            <input className="input-floating" type={this.props.type} value={this.props.value} 
                name={this.props.name} id={this.props.id || "input-" + this.props.name}
                onChange={e => this.props.onChange(e.target.value)} required={this.props.required}/>
            <label className="label-floating" htmlFor={this.props.id || "input-" + this.props.name}>{this.props.label}</label>
        </div>
    }
} 

