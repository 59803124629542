import Card from "../../components/card/Card";
import { ButtonSubmit } from "../../components/button/ButtonSubmit"
import InputValidate from "../../components/input/InputValidate"
import React, { Component, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form"
import authClient from "../../helpers/authClient";
import { Login } from "../../contract/auth";
import authHelper from "../../helpers/authHelper";
import { FormErrors } from "../../components/input/FormErrors";
import { useTranslation } from "react-i18next";

export default function SignUpPage() {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const [isRegisterSuccess, setRegisterSuccess] = useState(false);
    const [registerErrors, setRegisterErrors] = useState({});
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        const login = new Login(data.email, data.password, data.firstName, data.lastName, data.phoneNumber);
        const registerResult = await authClient.register(login);

        if (registerResult.successful) {
            navigate("/login");
        } else {
            setRegisterErrors(registerResult.errors);
        }
        setRegisterSuccess(registerResult.successful);
    }

    return (
        <Card>
            <div className="link text-secondary d-flex align-items-center justify-content-between pb-3">
                <h3>{t("registration")}</h3>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="row g-3">

                <div className="col-12">
                    <InputValidate label="Email" name="email" register={register} errors={errors}
                        validation={{
                            required: t("emailRequired"), pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: t("wrongEmailFormat"),
                            },
                        }} /></div>
                <div className="col-12">
                    <InputValidate label={t("password")} name="password" type="password" errors={errors} register={register} required
                        validation={{ required: t("passwordRequired"), minLength: { value: 1, message: t("minPassLength") } }} />
                </div>
                <div className="col-12">
                    <InputValidate label={t("firstName")} name="firstName" type="firstName" errors={errors} register={register} required
                        validation={{ required: t("firstNameRequired"), minLength: { value: 1, message: t("firstNameRequired") } }} />
                </div>
                <div className="col-12">
                    <InputValidate label={t("secondName")} name="lastName" type="lastName" errors={errors} register={register} required
                        validation={{ required: t("secondNameRequired"), minLength: { value: 1, message: t("secondNameRequired") } }} />
                </div>
                <div className="col-12">
                    <InputValidate label={t("phone")} name="phoneNumber" type="phoneNumber" errors={errors} register={register} required
                        validation={{ required: t("phoneRequired"),
                        pattern: {
                            value: /(7|8|9)\d{9}/,
                            message: t("phoneFormat"),
                        },
                        minLength: { value: 11, message: t("phoneRequired") } }} />
                </div>
                <FormErrors errors={registerErrors} formErrors={errors} successful={isRegisterSuccess} defaultMessage={t("success")} />
                <div className="d-flex justify-content-around flex-wrap gap-3 btn-container mt-4">
                    <ButtonSubmit text={t("registration")}></ButtonSubmit>
                    <Link className="btn btn-lg btn-outline-info btn-width" to="/login">
                        {t("back")}
                    </Link>
                </div>
            </form>
        </Card >
    )

}