import Card from "../components/card/Card";
import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import projectClient from "../helpers/projectClient";
import { Config } from '../config';
import { Download } from 'react-bootstrap-icons';
import VideoPlayerNew2 from '../components/videoPlayer/VideoPlayerNew2';

import renderClient from "../helpers/renderClient";
import authHelper from "../helpers/authHelper";

export default function SharedVideoPage() {
    const navigate = useNavigate();
    const { share } = useParams("share");
    const [videoSource, setVideoSource] = useState("");
    const [videoPreviewSource, setVideoPreviewSource] = useState("");
    const [project, setProject] = useState("");
    const video = useRef(null);
    const showOverlay = false;

    useEffect(() => {
        (async () => {
            const shareModel = await projectClient.getShare(share);

            if (shareModel == null) {
                navigate(`/`);
                return;
            }
            setProject(shareModel);
            updateVideoSource(share, shareModel.renderKey, shareModel.videoType);
        })();

        return () => {
        };
    }, []);

    const getPreviewVideoSource = async (id, proj) => {
        const token = authHelper.getToken();
        let result = '';
        let videoPreviewUrl = `${Config.host}/storage/${id}/previewstream/`;
        if (token != null) {
            const previewUrl = new URL(videoPreviewUrl);
            previewUrl.searchParams.set("token", token);
            previewUrl.searchParams.set("key", proj.renderKey);
            videoPreviewUrl = previewUrl.toString();
        }

        await renderClient.isFileExists(videoPreviewUrl).then(rc => {
            setVideoPreviewSource(rc.url);
            result = rc.url;
        }).catch(er => {
            setVideoPreviewSource(null);
            result = null;
        });
        return result;
    };

    const updateVideoSource = (share, renderKey, videoType) => {
       
        let videoUrl = videoType === 'original' ? `${Config.host}/storage/share/${share}/stream` : `${Config.host}/storage/share/${share}/previewstream`;

        if (renderKey != null) {
            const url = new URL(videoUrl);
            url.searchParams.set("key", renderKey);
            videoUrl = url.toString();
        }

        setVideoSource(videoUrl);
    }

    return (
        <Card>
            <div className="link text-black d-flex align-items-center justify-content-between mb-1">
                <h3>{project.name}</h3>

                <a className="btn btn-dark" href={videoSource}>
                    <Download />
                </a>
            </div>

            <div className="d-flex flex-column">
                {project &&
                    <VideoPlayerNew2
                        videoId="sharedVideoID"
                        src={videoSource}
                        videoRef={video}
                        // subtitlesUrl={subtitlesUrl}
                        // updateSubtitles={updateSubtitles}
                    />
                }
            </div>
        </Card >
    )

}