import React from "react";
import Navbar from "../navbar/Navbar";
import { NavLink, Outlet } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";

export default class Layout extends React.Component {

    render() {
        return (
            <>
            <div className="page_background">
                <header>
                    <Navbar />
                </header>
                <main className="flex-shrink-0">
                    <Container fluid>
                        <Row className="mb-auto">
                            <Col
                                xxl={{ span: 6, offset: 3 }}
                                xl={{ span: 8, offset: 2 }}
                                lg={{ span: 8, offset: 2 }}
                                md={{ span: 10, offset: 1 }}
                                sm={{ span: 10, offset: 1 }}
                                xs={{ span: 12 }}
                                className="my-3">
                                <Outlet />
                            </Col>
                        </Row>
                    </Container>
                </main>
                </div>
            </>)

    }
}

