// eslint-disable-next-line import/no-anonymous-default-export
export default {
	code: 'editor__code',
	heading: {
		h1: 'editor__heading-h1',
		h2: 'editor__heading-h2',
		h3: 'editor__heading-h3',
		h4: 'editor__heading-h4',
		h5: 'editor__heading-h5'
	},
	image: 'editor__image',
	link: 'editor__link',
	list: {
		listitem: 'editor__listitem',
		nested: {
			listitem: 'editor__nested-listitem'
		},
		ol: 'editor__list-ol',
		ul: 'editor__list-ul'
	},
	ltr: 'ltr',
	paragraph: 'editor__paragraph',
	placeholder: 'editor__placeholder',
	quote: 'editor__quote',
	rtl: 'rtl',
	text: {
		bold: 'editor__text-bold',
		code: 'editor__text-code',
		hashtag: 'editor__text-hashtag',
		italic: 'editor__text-italic',
		overflowed: 'editor__text-overflowed',
		strikethrough: 'editor__text-strikethrough',
		underline: 'editor__text-underline',
		underlineStrikethrough: 'editor__text-underlineStrikethrough'
	}
};
