import Card from "../../components/card/Card";
import { ButtonSubmit } from "../../components/button/ButtonSubmit"
import InputValidate from "../../components/input/InputValidate"
import React, { Component, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form"
import authClient from "../../helpers/authClient";
import { Login } from "../../contract/auth";
import authHelper from "../../helpers/authHelper";
import { FormErrors } from "../../components/input/FormErrors";
import { useTranslation } from "react-i18next";
import ForgotPassword from "./ForgotPassword";
import { Eye, EyeSlash } from "react-bootstrap-icons";

export default function LoginPage() {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const [isLoginSuccess, setLoginSuccess] = useState(true);
    const [loginErrors, setLoginErrors] = useState({});
    const [modalActive, setModalActive] = useState(false)
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const onSubmit = async (data) => {
        const login = new Login(data.email, data.password);
        const loginResult = await authClient.login(login);

        if (loginResult.successful) {
            authHelper.setToken(loginResult.token);
            navigate("/");
        } else {
            setLoginErrors(loginResult.errors);
        }
        setLoginSuccess(loginResult.successful);
    }

    return (
        <>
        <Card>
            <div className="link text-secondary d-flex align-items-center justify-content-between pb-3">
                <h3>{t("login")}</h3>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="row g-3">
                <div className="col-12">
                    <InputValidate label="Email" name="email" register={register} errors={errors}
                        validation={{ required: t("emailRequired") }} />
                </div>
                <div className="col-12 pass_input">
                    <InputValidate label= {t("password")} name="password" type={showPassword ? "text" : "password"} value={password} errors={errors} register={register} required
                        validation={{ required: t("passwordRequired") }} onChange={(e) => setPassword(e.target.value)}/>
                        {!showPassword ?
                      <Eye className="eye_button" value={showPassword} onClick={() => setShowPassword((prev) => !prev)}></Eye>
                            :
                      <EyeSlash className="eye_button" value={showPassword} onClick={() => setShowPassword((prev) => !prev)}></EyeSlash>
                        }
                </div>
                <FormErrors errors={loginErrors} defaultMessage={""} />
                {/* <div className="d-flex justify-content-end">
                    <p className="forgot_password_button" onClick={() => setModalActive(true)}>Забыли пароль?</p>
                </div> */}
                <div className="d-flex justify-content-around flex-wrap gap-3 btn-container mt-4">
                    <ButtonSubmit text={t("login")}></ButtonSubmit>
                    <Link className="btn btn-lg btn-outline-info btn-width" to="/sign-up">
                        {t("registration")}
                    </Link>
                </div>
            </form>
        </Card>
        <ForgotPassword active={modalActive} setActive={setModalActive} />
        </>
    )

}