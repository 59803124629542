import React from "react";
import './fileInput.scss'
import classNames from "classnames";
import { FileEarmarkArrowUp } from "react-bootstrap-icons";

export default class FileInputValidate extends React.Component {
    constructor(props) {
        super(props);
        this.handleChooseFile = this.handleChooseFile.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.register = props.register;
        this.unregister = props.unregister;

        this.name = props.name;
        this.validation = props.validation;
        this.setError = props.setError;

        this.state = { filesPaths: [], id: props.id, file: null }

        this.fileInputRef = React.createRef();
    }

    handleChooseFile = (event) => {
        this.fileInputRef.current.click();
    }

    handleChange = (event) => {
        const files = event.currentTarget.files;
        this.setState({ filesPath: files, file: files[0] })
        this.props?.handleOnChange(event, this.props.id);
    }

    componentWillUnmount = () => {
        if (!this.unregister) {
            return;
        }
        this.unregister(this.name);
    }

    render() {

        const inputClassName = classNames({
            "form-control": true,
            "custom-file-input": true,
            "is-invalid": this.props.errors && this.props.errors[this.name]
        });

        return (
            <div className="input-group custom-file-button">
                <div className="form-floating">
                    {this.register &&
                        <input type="file" accept="video/*" multiple="multiple" className={inputClassName} id={"file-" + this.props.id}
                            {...this.register(this.name, this.validation)}
                            placeholder={this.props.label} ref={this.fileInputRef} onChange={this.handleChange} />
                    }
                    {!this.register && <input type="file" className={inputClassName} id={"file-" + this.props.id}
                        placeholder={this.props.label} ref={this.fileInputRef} onChange={this.handleChange} />
                    }
                    {(this.props.errors == null || this.props.errors.hasOwnProperty(this.name) != true) && <label htmlFor={"file-" + this.props.id} > {this.props.label}</label>}

                    {this.props.errors && this.props.errors[this.name] && <label className="text-danger invalid-feedback" htmlFor={"id-" + this.name}>{this.props.errors[this.name]?.message}</label>}
                </div>
                <button type="button" className="input-group-text btn btn-file-upload" onClick={this.handleChooseFile}>
                    <FileEarmarkArrowUp />
                </button >
            </div>

        )
    }
}